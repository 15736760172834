import { Fragment } from 'react';
import CenterContainer from '../../components/centerContainer/CenterContainer';

const ComingSoon = () => {
	return (
		<Fragment>
			<div className="container-fluid">
				<div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<CenterContainer>
								<h2>Coming Soon</h2>
							</CenterContainer>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ComingSoon;
