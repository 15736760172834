export const getToken = () => {
	return localStorage.getItem('gbp-up-token');
};

export const setToken = (token) => {
	localStorage.setItem('gbp-up-token', token);
};

export const deleteToken = () => {
	localStorage.removeItem('gbp-up-token');
};

//admin user
export const StoreAdminUser = (user) => {
	localStorage.setItem('gbp-up-au', JSON.stringify(user));
};

export const GetAdminUser = () => {
	if (localStorage.getItem('gbp-up-au')) {
		return JSON.parse(localStorage.getItem('gbp-au'));
	}

	return null;
};

export const RemoveAdminUser = () => {
	localStorage.removeItem('gbp-up-au');
};

//offer type
export const getOfferType = () => {
	return localStorage.getItem('mb-offer-type');
};

export const setOfferType = (type) => {
	localStorage.setItem('mb-offer-type', type);
};

export const deleteOfferType = () => {
	localStorage.removeItem('mb-offer-type');
};
