import { Fragment, useEffect, useState } from 'react';

const KeywordStatItemV5 = ({ label, changeSymbol, value, change, selectable = false, onItemSelect }) => {
	const [ changeIcon, setChangeIcon ] = useState('fas fa-arrow-up');
	const [ itemSelected, setItemSelected ] = useState(false);

	useEffect(
		() => {
			if (change < 0) {
				setChangeIcon('bad fa-solid fa-arrow-trend-down');
				// setChangeIcon('');
			} else if (change > 0) {
				setChangeIcon('good fa-solid fa-arrow-trend-up');
			} else {
				setChangeIcon('');
			}
		},
		[ change ]
	);

	const handleSelectItem = () => {
		setItemSelected(!itemSelected);

		if (onItemSelect) {
			onItemSelect(!itemSelected, label);
		}
	};

	return (
		<Fragment>
			<div
				className={`keyword-stat-item ${selectable ? 'selectable' : ''} ${itemSelected ? 'selected' : ''}`}
				onClick={selectable ? handleSelectItem : undefined}
			>
				<Fragment>
					<div className="stat-data">
						{label && <small className="label">{label}</small>}
						<div className="value-container">
							<Fragment>
								{label === 'Average' && (value === null || value >= 21) ? (
									<div className="value">21+</div>
								) : (
									<div className="value">
										{!isNaN(value) ? `${value % 1 > 0 ? value.toFixed(1) : value}` : '0'}
									</div>
								)}
							</Fragment>
						</div>
					</div>
					<div className="stat-data">
						<div
							className={`change ${change && change > 0 ? 'good' : 'bad'} ${change &&
								label === 'Average' &&
								'average'}`}
						>
							{!!change &&
							// change !== 0 && (
							change > 0 && (
								<Fragment>
									<span>
										{change > 0 ? '+' : ''}

										{`${change % 1 > 0 ? change.toFixed(1) : change.toFixed(0)} ${changeSymbol
											? changeSymbol
											: ''}`}
									</span>
								</Fragment>
							)}
						</div>
					</div>
					{itemSelected && (
						<div className="stat-data selected">
							<i className="fa-solid fa-circle-check" />
						</div>
					)}
				</Fragment>
			</div>
		</Fragment>
	);
};

export default KeywordStatItemV5;
