import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import './PageHeadline.css';
import UserProfileInfo from '../account/userProfileInfo/UserProfileInfo';
import { useStateValue } from '../../StateProvider';

const PageHeadline = ({
	campaign,
	headline,
	linkText = 'Go Back',
	linkUrl = '',
	className = '',
	buttonsHtml = null,
	leftSideHtml = null,
	showUserInfo = true
}) => {
	const navigate = useNavigate();

	return (
		<div className={`page-header-container ${className ? className : ''}`}>
			<div className="col-12 col-md-6 col-xl-9 mb-2 mb-sm-0">
				{headline && (
					<div className="headline-wrapper">
						<div className="d-flex flex-column flex-xl-row align-items-start align-items-xl-center">
							{/* {linkUrl && (
								<button
									className="btn btn-link me-4 ps-0 headline-link"
									onClick={() => navigate(linkUrl)}
								>
									<i className="fa-solid fa-arrow-left-long me-2" />
									{linkText}
								</button>
							)} */}
							<h5 className="headline-title text-truncate">
								<span>{headline}</span>
							</h5>
						</div>
					</div>
				)}
				{leftSideHtml && <Fragment>{leftSideHtml}</Fragment>}
			</div>
			<div className="col-12 col-md-6 col-xl-3 justify-content-end">
				{buttonsHtml && <Fragment>{buttonsHtml}</Fragment>}
			</div>

			{/* {buttonsHtml && <Fragment>{buttonsHtml}</Fragment>} */}

			{/* {user && showUserInfo && <UserProfileInfo />} */}
		</div>
	);
};

export default PageHeadline;
