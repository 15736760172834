import './ProfileIcon.css';
import { useEffect, useState, Fragment } from 'react';
import AvatarLettersIcon from '../avatar/AvatarLettersIcon';
import { Link } from 'react-router-dom';
import { deleteToken } from '../../../utils/StorageUtil';
import { actionTypes } from '../../../reducer';
import { useStateValue } from '../../../StateProvider';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { isMobile } from '../../../utils/Common';
// import LogoutIcon from '../../img/sign_out_icon.png';

const ProfileIcon = ({ onLinkClick }) => {
	const [ { user }, dispatch ] = useStateValue();
	const [ tempUser, setTempUser ] = useState({});
	const [ showLogoutLink, setShowLogoutLink ] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(
		() => {
			setTempUser(user);

			if (isMobile() || location.pathname.includes('/account')) {
				setShowLogoutLink(true);
			} else {
				setShowLogoutLink(false);
			}
		},
		[ user, location ]
	);

	const logout = () => {
		deleteToken();
		dispatch({
			type : actionTypes.SET_USER,
			user : null
		});

		dispatch({
			type           : actionTypes.SELECTED_THREAD,
			selectedThread : null
		});

		navigate('/login');
	};

	const handleLinkClick = () => {
		if (onLinkClick) {
			onLinkClick();
		}
	};

	return (
		<div className="profile-details-container">
			<Fragment>
				<div className="profile-details-inner">
					<AvatarLettersIcon name={tempUser.displayName} />
					<Link to="/account" className="profile-details-link" onClick={handleLinkClick}>
						<div className="profile-details">
							<div className="full-name">{tempUser.displayName}</div>
							<div className="manage-account">Manage Account</div>
							{showLogoutLink && (
								<div className="logout-link mt-2" onClick={logout}>
									<i className="fa-solid fa-right-from-bracket me-2" />
									<span>Log Out</span>
								</div>
							)}
						</div>
					</Link>
				</div>
			</Fragment>
		</div>
	);
};

export default ProfileIcon;
