import { useState, useEffect, Fragment } from 'react';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import './Login.css';
import { AppLogin } from '../../Services';
import { StoreAdminUser, setToken } from '../../utils/StorageUtil';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../logo/Logo';

const Login = ({ onSuccessfulLogin }) => {
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ { user, adminUser }, dispatch ] = useStateValue();
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ wrongCredentials, setWrongCredentials ] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (user && !isSubmitting) {
			navigate('/campaigns');
		}
	});

	const login = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);

		let data = { email: email, password: password };

		const response = await AppLogin(data);

		if (response && response.token) {
			const { token, ...tempUser } = response;

			setToken(token);

			if (tempUser.isAdmin) {
				dispatch({
					type      : actionTypes.SET_ADMIN_USER,
					adminUser : tempUser
				});

				StoreAdminUser(response);
			}

			if (onSuccessfulLogin) {
				onSuccessfulLogin(tempUser);
			}

			setTimeout(function() {
				dispatch({
					type : actionTypes.SET_USER,
					user : tempUser
				});

				setIsSubmitting(false);
				navigate('/campaigns');
			}, 1000);
		} else {
			setIsSubmitting(false);
			setWrongCredentials(true);
		}
	};

	return (
		<div className="container-fluid">
			<div className="container login-container">
				<div className="login-wrapper">
					<div className="text-center mb-4">
						<Logo />
					</div>

					<h4 className="text-center mb-4">Sign in to Map Boost</h4>
					<form onSubmit={login}>
						<div className="row mb-4">
							<div className="col-12">
								<label htmlFor="email" className="form-label">
									Email:
								</label>
								<input
									type="email"
									className="form-control"
									id="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									disabled={isSubmitting}
								/>
							</div>
						</div>
						<div className="row mb-4">
							<div className="col-12">
								<label htmlFor="password" className="form-label">
									Password:
								</label>
								<input
									type="password"
									className="form-control"
									id="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									disabled={isSubmitting}
								/>
							</div>
						</div>
						<div className="row pt-2">
							<div className="col-12">
								<button className="btn btn-primary w-100" type="submit">
									{isSubmitting ? (
										<Fragment>
											<span className="spinner-border m-0 me-2" />
											<span>Logging in...</span>
										</Fragment>
									) : (
										<span>Login</span>
									)}
								</button>
								{wrongCredentials && <div className="mt-2 text-center">Wrong email or password!</div>}
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<Link
									to="/forgot-password"
									className="btn btn-link btn-block btn-forgot-password w-100 mt-3 p-0"
								>
									Forgot Your Password?
								</Link>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Login;
