import { Fragment, useEffect } from 'react';
import PageHeadline from '../../components/pageHeadline/PageHeadline';

const Support = () => {
	useEffect(() => {
		document.title = 'Support';
	}, []);

	return (
		<Fragment>
			<div className="row">
				<div className="col-12">
					<PageHeadline headline="Support" showSearchBox={false} />
				</div>
			</div>
		</Fragment>
	);
};

export default Support;
