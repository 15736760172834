import { Route, Routes } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';
import CampaignsList from '../../screens/campaigns/campaignsList/CampaignsList';
import CampaignDetails from '../../screens/campaigns/campaignDetails/CampaignDetails';
import PaymentsList from '../../screens/payments/paymentsList/PaymentsList';
import ReportsList from '../../screens/reports/reportsList/ReportsList';
import Account from '../account/Account';
import LeftNavigation from '../leftNavigation/LeftNavigation';
import MainAppContentArea from '../mainAppContentArea/MainAppContentArea';
import './MainApp.css';
import OffersList from '../../screens/offers/offersList/OffersList';
import ComingSoon from '../../screens/comingSoon/ComingSoon';
import Support from '../../screens/support/Support';

const MainApp = () => {
	return (
		<div className="app-container">
			<LeftNavigation />
			<MainAppContentArea>
				<Routes>
					<Route path="/campaigns/:campaignId" element={<CampaignDetails />} />
					<Route path="/campaigns" element={<CampaignsList />} />
					<Route path="/reports/:campaignId?" element={<ReportsList />} />
					<Route path="/account" element={<Account />} />
					<Route path="/payments" element={<PaymentsList />} />
					<Route path="/learning" element={<ComingSoon />} />
					<Route path="/cart" element={<OffersList />} />
					{/* <Route path="/cart" element={<ComingSoon />} /> */}
					<Route path="/support" element={<Support />} />
					<Route path="/*" element={<CampaignsList />} />
				</Routes>
			</MainAppContentArea>
		</div>
	);
};

export default MainApp;
