import { useEffect, useState, Fragment } from 'react';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { toast } from 'react-toastify';
import CancelCampaignConfirmDialog from '../cancelCampaignConfirmDialog/CancelCampaignConfirmDialog';

const CancelCampaignDialog = ({ campaignId, onSubmit, onClose }) => {
	const [ cancelReason, setCancelReason ] = useState();
	const [ showCancelConfirmDialog, setShowCancelConfirmDialog ] = useState(false);

	const handleCancelCampaign = async (e) => {
		// if (!cancelReason || cancelReason.trim() === '') {
		// 	toast.error('Enter reason for cancellation to continue');
		// 	return;
		// }

		toggleCancelConfirmDialog();

		if (e) e.preventDefault();
	};

	const handleCloseDialog = () => {
		if (onClose) {
			onClose();
		}
	};

	const toggleCancelConfirmDialog = async (e) => {
		setShowCancelConfirmDialog(!showCancelConfirmDialog);

		if (e) e.preventDefault();
	};

	const handleOnCancelConfirm = async (e) => {
		toggleCancelConfirmDialog();
		toast.success('Campaign canceled successfully');

		if (onSubmit) {
			onSubmit();
		}
	};

	return (
		<Fragment>
			<ModalDialog className="text-start" position="right-sidebar">
				<ModalDialogContent align="start">
					<h4 className="mb-4">
						<span>Cancel Campaign</span>
					</h4>

					<div className="row mb-4">
						<label htmlFor="name" className="form-label col-12">
							Why are you cancelling?
						</label>
						<div className="col-12">
							<textarea
								className="form-control"
								name="name"
								value={cancelReason || ''}
								onChange={(e) => setCancelReason(e.target.value)}
								rows={5}
							/>
						</div>
					</div>
				</ModalDialogContent>
				<ModalDialogButtons>
					<div className="row">
						<div className="text-start col-12">
							<button className="btn btn-primary" onClick={handleCancelCampaign}>
								<span>Submit</span>
							</button>
							<button className="btn btn-outline-primary ms-2" onClick={(e) => handleCloseDialog()}>
								<span>Cancel</span>
							</button>
						</div>
					</div>
				</ModalDialogButtons>
			</ModalDialog>

			{showCancelConfirmDialog && (
				<CancelCampaignConfirmDialog
					campaignId={campaignId}
					cancelReason={cancelReason}
					onSubmit={handleOnCancelConfirm}
					onClose={toggleCancelConfirmDialog}
				/>
			)}
		</Fragment>
	);
};

export default CancelCampaignDialog;
