import { useEffect, useState, Fragment } from 'react';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { GetActiveCampaigns, SubmitAskQuestionRequest } from '../../../Services';
import { toast } from 'react-toastify';

const AskQuestionDialog = ({ campaignId, onSubmit, onClose }) => {
	const [ activeCampaigns, setActiveCampaigns ] = useState();
	const [ askQuestionCampaign, setAskQuestionCampaign ] = useState();
	const [ askQuestionSubject, setAskQuestionSubject ] = useState();
	const [ askQuestionText, setAskQuestionText ] = useState();
	const [ isSubmittingAskQuestion, setIsSubmittingAskQuestion ] = useState(false);

	useEffect(() => {
		getCampaigns();
	}, []);

	const getCampaigns = async () => {
		const response = await GetActiveCampaigns(campaignId);

		if (response) {
			setActiveCampaigns(response.sort((a, b) => a.name.localeCompare(b.name)));

			if (campaignId) {
				setAskQuestionCampaign(campaignId);
			}
		}
	};

	const handleSubmit = async () => {
		if (!askQuestionCampaign || askQuestionCampaign.trim() === '') {
			toast.error('Please select campaign.');
			return;
		}

		if (!askQuestionSubject || askQuestionSubject.trim() === '') {
			toast.error('Please enter subject.');
			return;
		}

		if (!askQuestionText || askQuestionText.trim() === '') {
			toast.error('Please enter question.');
			return;
		}

		setIsSubmittingAskQuestion(true);

		let data = { campaignId: askQuestionCampaign, subject: askQuestionSubject, question: askQuestionText };

		let response = await SubmitAskQuestionRequest(askQuestionCampaign, data);

		if (response.success) {
			toast.success('Question successfully submitted');

			if (onSubmit) {
				onSubmit();
			}
		}

		setIsSubmittingAskQuestion(false);
	};

	const handleCloseDialog = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<ModalDialog className="text-start" position="right-sidebar">
			<ModalDialogContent align="start">
				<h4 className="mb-4">
					<span>How Can We Help?</span>
				</h4>
				<form className="ask-question-form">
					<div className="row mb-3">
						<label htmlFor="askQuestionCampaigns" className="form-label col-12">
							Campaign:
						</label>
						<div className="col-12 mb-3">
							<select
								className="form-select"
								name="askQuestionCampaigns"
								value={askQuestionCampaign}
								onChange={(e) => setAskQuestionCampaign(e.target.value)}
								disabled={isSubmittingAskQuestion}
							>
								<Fragment>
									<option value="" />
									{activeCampaigns &&
										activeCampaigns.map((activeCampaign, index) => (
											<option key={activeCampaign.id} value={activeCampaign.id}>
												{`${activeCampaign.name}${activeCampaign.nameExtension
													? ` - ${activeCampaign.nameExtension}`
													: ''}`}
											</option>
										))}
								</Fragment>
							</select>
						</div>
						<label htmlFor="askQuestionSubject" className="form-label col-12">
							Subject:
						</label>
						<div className="col-12 mb-3">
							<input
								type="text"
								className="form-control"
								name="askQuestionSubject"
								value={askQuestionSubject || ''}
								onChange={(e) => setAskQuestionSubject(e.target.value)}
								required
								disabled={isSubmittingAskQuestion}
							/>
						</div>
						<label htmlFor="askQuestionText" className="form-label col-12">
							What is your question?
						</label>
						<div className="col-12 mb-3">
							<textarea
								className="form-control"
								name="askQuestionText"
								value={askQuestionText || ''}
								onChange={(e) => setAskQuestionText(e.target.value)}
								rows={5}
								required
								disabled={isSubmittingAskQuestion}
							/>
						</div>
					</div>
				</form>
			</ModalDialogContent>
			<ModalDialogButtons>
				<div className="row">
					<div className="text-start col-12">
						<button className="btn btn-primary" onClick={handleSubmit}>
							{isSubmittingAskQuestion ? (
								<Fragment>
									<span className="spinner-border m-0 me-2" />
									<span>Submitting...</span>
								</Fragment>
							) : (
								<span>Submit</span>
							)}
						</button>
						<button className="btn btn-outline-primary" onClick={handleCloseDialog}>
							<span>Cancel</span>
						</button>
					</div>
				</div>
			</ModalDialogButtons>
		</ModalDialog>
	);
};

export default AskQuestionDialog;
