import { zeroPad } from '../../utils/Common';

const ShowCounter = ({ days, hours, minutes, seconds }) => {
	return (
		<div className="show-counter">
			<div className="countdown-link">
				<div className="countdown">
					<p>{zeroPad(days, 2)}</p>
					<span>Days</span>
				</div>
				<div className="countdown">
					<p>{zeroPad(hours, 2)}</p>
					<span>Hours</span>
				</div>
				<div className="countdown">
					<p>{zeroPad(minutes, 2)}</p>
					<span>Mins</span>
				</div>
				<div className="countdown">
					<p>{zeroPad(seconds, 2)}</p>
					<span>Seconds</span>
				</div>
			</div>
		</div>
	);
};

export default ShowCounter;
