import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useStateValue } from './StateProvider';
import { Fragment, useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetUser } from './Services';
import { GetAdminUser, deleteToken, getToken } from './utils/StorageUtil';
import { actionTypes } from './reducer';
import Loader from './components/loader/Loader';
import Login from './components/login/Login';
import MainApp from './components/mainApp/MainApp';
import PublicViewCampaignReportScreenV5 from './screens/reports/publicViewCampaignReportV5/PublicViewCampaignReportScreenV5';
import PublicCustomerReportInfoPage from './screens/reports/customerReportInfo/PublicCustomerReportInfoPage';
import ForgotPassword from './components/forgotPassword/ForgotPassword';
import ResetPassword from './components/resetPassword/ResetPassword';
import OfferDetails from './screens/offers/offerDetails/OfferDetails';
import OfferDetailsSuccess from './screens/offers/offerDetails/OfferDetailsSuccess';
import CreateRequirements from './screens/requirements/createRequirements/CreateRequirements';
import CreateRequirementsSuccess from './screens/requirements/createRequirements/CreateRequirementsSuccess';
// import RequirementsApprovalPage from './screens/requirements/requirementsApprovalPage/RequirementsApprovalPage';

function App() {
	const [ { user, host }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ toastifyPosition, setToastifyPosition ] = useState('bottom-left');
	const [ chatLoaded, setChatLoaded ] = useState(false);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		getUser();
		handleLiveChat();
	}, []);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW <= 767) {
			setToastifyPosition('bottom-center');
		} else {
			setToastifyPosition('bottom-left');
		}
	};

	const getUser = async () => {
		let token = getToken();

		if (token) {
			try {
				const response = await GetUser();

				if (response && response.id) {
					const { ...tempUser } = response;

					dispatch({
						type : actionTypes.SET_USER,
						user : tempUser
					});

					if (GetAdminUser()) {
						let adminUser = GetAdminUser();

						dispatch({
							type      : actionTypes.SET_ADMIN_USER,
							adminUser : adminUser
						});
					}
				} else {
					let tempToken = getToken();

					if (tempToken) {
						deleteToken();
					}
				}

				setIsLoading(false);
			} catch (error) {
				let tempToken = getToken();

				if (tempToken) {
					deleteToken();
				}

				setIsLoading(false);
			}
		} else {
			setTimeout(function() {
				setIsLoading(false);
			}, 300);
		}
	};

	const handleLiveChat = () => {
		let pageSlugs = [
			'/customer/report',
			//'/map-boost-approval',
			'/map-boost-approval-change-thank-you',
			'/map-boost-approval-thank-you'
		];
		let currentUrl = window.location.href;

		if (pageSlugs.some((x) => currentUrl.includes(x)) && !chatLoaded) {
			// load LC script
			window.__lc = window.__lc || {};
			window.__lc.license = 10943002;

			if (user) {
				if (user.Status === 'Trial') {
					window.__lc.group = 3;
				} else {
					window.__lc.group = 2;
				}
			} else {
				window.__lc.group = 2;
			}

			var lc = document.createElement('script');
			lc.type = 'text/javascript';
			lc.async = true;
			lc.src =
				('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
			var s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(lc, s);

			setChatLoaded(true);
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className="app">
			<Fragment>
				<Routes>
					{/* <Route path="/*" element={<div />} /> */}

					{user ? (
						<Fragment>
							<Route
								path="/view/report/v5/:campaignId/*"
								element={<PublicViewCampaignReportScreenV5 />}
							/>
							<Route
								path="/customer/report/:reportHistoryId"
								element={<PublicCustomerReportInfoPage />}
							/>
							<Route path="/*" element={<MainApp />} />
						</Fragment>
					) : (
						<Fragment>
							<Route path="/login/*" element={<Login />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/reset-password" element={<ResetPassword />} />
							{/* <Route exact path="/" element={<div />} /> */}
							<Route path="/*" element={<Login />} />
						</Fragment>
					)}
					<Route path="/orders/:id/requirements" element={<CreateRequirements />} />
					{/* <Route path="/requirements/:id/approve" element={<RequirementsApprovalPage />} /> */}
					<Route path="/requirements/success" element={<CreateRequirementsSuccess />} />
					<Route path="/orders/:id?" element={<OfferDetails />} />
					<Route path="/orders/:id/success" element={<OfferDetailsSuccess />} />
				</Routes>
				<ToastContainer
					position={toastifyPosition}
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					limit={1}
				/>
			</Fragment>
		</div>
	);
}

export default App;
