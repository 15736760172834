import { useEffect, useState, Fragment } from 'react';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import Spinner from '../../../components/spinner/Spinner';
import { GetRequirementDetails, UpdateRequirements } from '../../../Services';
import { toast } from 'react-toastify';

const RequirementsDialog = ({ id, onSubmit, onClose }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ requirementsDetailsData, setRequirementsDetailsData ] = useState({});

	useEffect(() => {
		getRequirementsDetails();
	}, []);

	const getRequirementsDetails = async () => {
		setIsLoading(true);

		let response = await GetRequirementDetails(id);

		if (response) {
			setRequirementsDetailsData(response);
		}

		setIsLoading(false);
	};

	const handleDialogClose = () => {
		if (onClose) {
			onClose();
		}
	};

	const handleRequirementsDetailsDataChange = (e) => {
		setRequirementsDetailsData({
			...requirementsDetailsData,
			[e.target.name]: e.target.value
		});
	};

	const saveRequirementsDetails = async () => {
		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		setIsSaving(true);

		let response = await UpdateRequirements(id, requirementsDetailsData);

		if (response.success) {
			if (onSubmit) {
				onSubmit();
			}
		} else {
			toast.error('Process failed. ' + response.message);
		}

		setIsSaving(false);
	};

	return (
		<ModalDialog position="right-sidebar" className="text-start">
			<ModalDialogContent align="start">
				<h4 className="mb-3">
					{requirementsDetailsData && requirementsDetailsData.id ? (
						<Fragment>
							{requirementsDetailsData.status === 'Approval' ? 'Edit Requirements' : 'Requirements'}
						</Fragment>
					) : (
						'Requirements'
					)}
				</h4>
				<form className="row requirements-details-form">
					{isLoading ? (
						<div className="text-center">
							<Spinner />
						</div>
					) : (
						<Fragment>
							<div className="col-12 mb-3">
								<label htmlFor="company" className="form-label">
									Company:
								</label>
								<input
									type="text"
									className="form-control"
									name="company"
									value={requirementsDetailsData.company || ''}
									onChange={handleRequirementsDetailsDataChange}
									disabled={isSaving}
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="website" className="form-label">
									Website Url:
								</label>
								<input
									type="text"
									className="form-control"
									name="website"
									value={requirementsDetailsData.website || ''}
									onChange={handleRequirementsDetailsDataChange}
									disabled={isSaving}
									required
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="googleBusinessUrl" className="form-label">
									Google Maps Url:
								</label>
								<input
									type="text"
									className="form-control"
									name="googleBusinessUrl"
									value={requirementsDetailsData.googleBusinessUrl || ''}
									onChange={handleRequirementsDetailsDataChange}
									disabled={isSaving}
									required
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="address" className="form-label">
									Business Address:
								</label>
								<input
									type="text"
									className="form-control"
									name="address"
									value={requirementsDetailsData.address || ''}
									onChange={handleRequirementsDetailsDataChange}
									disabled={isSaving}
									required
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="phone" className="form-label">
									Business Phone:
								</label>
								<input
									type="text"
									className="form-control"
									name="phone"
									value={requirementsDetailsData.businessPhone || ''}
									onChange={handleRequirementsDetailsDataChange}
									disabled={isSaving}
									required
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="specialInstructions" className="form-label">
									Special Instructions:
								</label>
								<textarea
									className="form-control"
									name="specialInstructions"
									value={requirementsDetailsData.specialInstructions || ''}
									onChange={handleRequirementsDetailsDataChange}
									disabled={isSaving}
									rows={5}
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="industry" className="form-label">
									Primary Service / Industry:
								</label>
								<input
									type="text"
									className="form-control"
									name="industry"
									value={requirementsDetailsData.industry || ''}
									onChange={handleRequirementsDetailsDataChange}
									disabled={isSaving}
									required
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="services" className="form-label">
									Top 5 Services:
								</label>
								<textarea
									className="form-control"
									name="services"
									value={requirementsDetailsData.services || ''}
									onChange={handleRequirementsDetailsDataChange}
									disabled={isSaving}
									rows={5}
									required
								/>
							</div>
							<div className="col-12">
								<label htmlFor="keywords" className="form-label">
									Keywords:
								</label>
								<textarea
									className="form-control"
									name="keywords"
									value={requirementsDetailsData.keywords || ''}
									onChange={handleRequirementsDetailsDataChange}
									disabled={isSaving}
									rows={5}
									required
								/>
							</div>
							<div className="col-12 pt-2">
								<hr />
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="mobilePhone" className="form-label">
									Mobile Phone (for SMS notifications):
								</label>
								<input
									type="text"
									className="form-control"
									name="mobilePhone"
									value={requirementsDetailsData.mobilePhone || ''}
									onChange={handleRequirementsDetailsDataChange}
									disabled={isSaving}
									required
								/>
							</div>
						</Fragment>
					)}
				</form>
			</ModalDialogContent>
			<ModalDialogButtons>
				{(!requirementsDetailsData.id || requirementsDetailsData.status !== 'Completed') && (
					<button className="btn btn-primary" type="button" onClick={() => saveRequirementsDetails()}>
						{isSaving ? (
							<Fragment>
								<span className="spinner-border m-0 me-2" />
								<span>Saving</span>
							</Fragment>
						) : (
							<span>Save</span>
						)}
					</button>
				)}

				<button className="btn btn-outline-primary ms-2" type="button" onClick={handleDialogClose}>
					Cancel
				</button>
			</ModalDialogButtons>
		</ModalDialog>
	);
};

export default RequirementsDialog;
