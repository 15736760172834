import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import './OffersList.css';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import TableFooter from '../../../components/table/footer/TableFooter';
import { useStateValue } from '../../../StateProvider';
import { GetUserOffers } from '../../../Services';
import { Link } from 'react-router-dom';
import { setOfferType } from '../../../utils/StorageUtil';

const OffersList = () => {
	const [ { user }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ offers, setOffers ] = useState([]);

	useEffect(() => {
		document.title = 'Cart';

		getOffers(true);
	}, []);

	useEffect(() => {
		document.addEventListener('visibilitychange', handleTabFocus);

		// Specify how to clean up after this effect:
		return () => {
			document.removeEventListener('visibilitychange', handleTabFocus);
		};
	}, []);

	// User has switched back to the tab
	const handleTabFocus = () => {
		if (document.visibilityState === 'visible') {
			//console.log('Tab is in focus');
			getOffers();
		}
	};

	const getOffers = async () => {
		setIsLoading(true);

		let response = await GetUserOffers();

		if (response && response.length > 0) {
			let tempActiveOffers = response.filter((x) => x.status === 'Active');

			tempActiveOffers = tempActiveOffers.sort((a, b) => a.name.localeCompare(b.name));

			setOffers(tempActiveOffers);
		}

		setIsLoading(false);
	};

	const handleProcessOrder = (offerId) => {
		setOfferType('user');
		window.open(`/orders/${offerId}`, '_blank');
	};

	return (
		<Fragment>
			<div className="row">
				<div className="col-12">
					<PageHeadline headline="Cart" showSearchBox={false} />
				</div>
			</div>

			<Fragment>
				<div className="main-content-container offers-list-container">
					<div className="main-content-inner-container">
						<table className="items-list offers-list table table-responsive">
							<thead>
								<tr>
									<th scope="col">Product</th>
									<th className="amount" scope="col">
										Amount
									</th>
									<th className="task-action" scope="col" />
								</tr>
							</thead>
							{isLoading ? (
								<tbody>
									<tr>
										<td className="p-3" colSpan={5}>
											<Spinner />
										</td>
									</tr>
								</tbody>
							) : (
								<tbody>
									{offers && offers.length > 0 ? (
										offers.map((offer, index) => {
											return (
												<Fragment key={index}>
													<tr>
														<td className="campaign-name text-truncate" title={offer.name}>
															{offer.name}
														</td>
														<td className="amount">${offer.price}</td>
														<td className="task-action text-end">
															{/* <Link
																className="btn btn-link py-0"
																to={`/orders/${offer.id}`}
																title="View Order"
																target="_blank"
															>
																Order
															</Link> */}
															<button
																type="button"
																className="btn btn-link py-0"
																title="View Order"
																onClick={() => handleProcessOrder(offer.id)}
															>
																Order
															</button>
														</td>
													</tr>
												</Fragment>
											);
										})
									) : (
										<tr>
											<td className="p-3" colSpan={5}>
												<i>No offers</i>
											</td>
										</tr>
									)}
								</tbody>
							)}

							{!isLoading && (
								<TableFooter
									list={offers}
									colspan={5}
									showRefreshButton={true}
									onListRefresh={() => getOffers(true)}
								/>
							)}
						</table>
					</div>
				</div>
			</Fragment>
		</Fragment>
	);
};

export default OffersList;
