import { useState, useEffect, Fragment } from 'react';
import './ResetPassword.css';
import { ResetUserPassword } from '../../Services';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Logo from '../logo/Logo';
import { toast } from 'react-toastify';

const ResetPassword = () => {
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ password, setPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');
	const [ queryParameters ] = useSearchParams();
	const [ userId, setUserId ] = useState();
	const [ token, setToken ] = useState();
	const navigate = useNavigate();

	useEffect(() => {
		if (queryParameters) {
			setUserId(queryParameters.get('user'));
			setToken(queryParameters.get('token').replaceAll(' ', '+'));
		}
	}, []);

	const resetPassword = async (e) => {
		e.preventDefault();

		if (!password || !confirmPassword) {
			toast.error('Please enter the new password');
			return;
		}

		if (password !== confirmPassword) {
			toast.error('Passwords do not match');
			return;
		}

		setIsSubmitting(true);

		let data = { userId: userId, password: password, token: token };

		const response = await ResetUserPassword(data);

		if (response.success) {
			toast.success('Password reset successfully');

			setTimeout(function() {
				setIsSubmitting(false);
				navigate('/login');
			}, 1000);
		} else {
			toast.error(response.message);
			setIsSubmitting(false);
		}
	};

	return (
		<div className="container-fluid">
			<div className="container reset-password-container">
				<div className="reset-password-wrapper">
					<div className="text-center mb-4">
						<Logo />
					</div>

					<h4 className="text-center mb-4">Reset Password</h4>
					<form onSubmit={resetPassword}>
						<div className="row mb-4">
							<div className="col-12">
								<label htmlFor="password" className="form-label">
									New Password:
								</label>
								<input
									type="password"
									className="form-control"
									id="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									disabled={isSubmitting}
								/>
							</div>
						</div>
						<div className="row mb-4">
							<div className="col-12">
								<label htmlFor="confirmPassword" className="form-label">
									Confirm Password:
								</label>
								<input
									type="password"
									className="form-control"
									id="confirmPassword"
									value={confirmPassword}
									onChange={(e) => setConfirmPassword(e.target.value)}
									disabled={isSubmitting}
								/>
							</div>
						</div>
						<div className="row pt-2">
							<div className="col-12">
								<button className="btn btn-primary w-100" type="submit">
									{isSubmitting ? (
										<Fragment>
											<span className="spinner-border m-0 me-2" />
											{/* <span>Reset</span> */}
										</Fragment>
									) : (
										<span>Reset Password</span>
									)}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
