import { useEffect, useState, Fragment } from 'react';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import Spinner from '../../../components/spinner/Spinner';
import { GetDefaultOffers } from '../../../Services';
import { setOfferType } from '../../../utils/StorageUtil';

const NewCampaignDialog = ({ campaignId, onSelect, onClose }) => {
	const [ defaultOffers, setDefaultOffers ] = useState();
	const [ isLoadingOffers, setIsLoadingOffers ] = useState(false);

	useEffect(() => {
		getDefaultOffers();
	}, []);

	const getDefaultOffers = async () => {
		setIsLoadingOffers(true);

		let response = await GetDefaultOffers();

		if (response && response.length) {
			setDefaultOffers(response);
		}

		setIsLoadingOffers(false);
	};

	// const handleSelectOffer = (offerId) => {
	// 	if (onSelect) {
	// 		onSelect(offerId);
	// 	}
	// };

	const handleCloseDialog = () => {
		if (onClose) {
			onClose();
		}
	};

	const handleNewCampaign = (offerId) => {
		setOfferType('default');
		window.open(`/orders/${offerId}`, '_blank');
	};

	return (
		<ModalDialog className="text-start" position="right-sidebar">
			<ModalDialogContent align="start">
				<h4 className="mb-4">
					<span>Select An Offer</span>
				</h4>
				{isLoadingOffers ? (
					<Spinner />
				) : (
					<table className="campaigns-list table table-responsive table-bordered default-offers-list">
						{/* <thead>
							<tr>
								<th className="name" scope="col">
									Name
								</th>
								<th className="select-offer" scope="col" />
							</tr>
						</thead> */}
						<tbody>
							{defaultOffers && defaultOffers.length > 0 ? (
								defaultOffers.map((offer, index) => (
									<tr key={index}>
										<td className="name">
											<div className="offer-title">
												<span>{offer.name}</span>
											</div>
											{/* <div className="offer-description">{offer.description}</div> */}
										</td>
										{/* <td className="price">${offer.price}</td> */}
										<td className="select-offer">
											{/* <a
												className="btn btn-link p-0"
												href={`/orders/${offer.id}`}
												target="_blank"
											>
												Select
											</a> */}
											<button
												type="button"
												className="btn btn-link p-0"
												onClick={() => handleNewCampaign(offer.id)}
											>
												Select
											</button>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td className="p-3" colSpan={8}>
										<i>No offers</i>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				)}
			</ModalDialogContent>
			<ModalDialogButtons>
				<div className="row">
					<div className="text-start col-12">
						<button className="btn btn-outline-primary" onClick={handleCloseDialog}>
							<span>Cancel</span>
						</button>
					</div>
				</div>
			</ModalDialogButtons>
		</ModalDialog>
	);
};

export default NewCampaignDialog;
