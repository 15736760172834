import { Fragment, useEffect, useRef, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import './ReportsList.css';
import { GetActiveCampaigns, GetReportHistoryListWithSproc, GetReportHistoryTrackingLogs } from '../../../Services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import moment from 'moment';
import { useStateValue } from '../../../StateProvider';
import TableFooter from '../../../components/table/footer/TableFooter';

const ReportsList = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ allSentReports, setAllSentReports ] = useState();
	const [ sentReportsView, setSentReportsView ] = useState();
	const [ activeCampaigns, setActiveCampaigns ] = useState();
	const [ selectedCampaignId, setSelectedCampaignId ] = useState();
	const [ { user }, dispatch ] = useStateValue();
	const { campaignId } = useParams();
	const [ showTrackingDetailsDialog, setShowTrackingDetailsDialog ] = useState(false);
	const [ trackingDetailsData, setTrackingDetailsData ] = useState([]);
	const searchListInputRef = useRef();

	useEffect(
		() => {
			if (campaignId) {
				setSelectedCampaignId(campaignId);
			}
		},
		[ campaignId ]
	);

	useEffect(() => {
		getCampaigns();
		getReportHistory(true);
	}, []);

	useEffect(
		() => {
			if (activeCampaigns && sentReportsView) {
				setIsLoading(false);
			}
		},
		[ activeCampaigns, sentReportsView ]
	);

	useEffect(
		() => {
			if (allSentReports) {
				let tempSentReports = selectedCampaignId
					? allSentReports.filter((x) => x.campaignId === selectedCampaignId)
					: allSentReports;
				setSentReportsView(tempSentReports);
			} else {
				setSentReportsView([]);
			}
		},
		[ selectedCampaignId, allSentReports ]
	);

	const filterList = () => {
		if (allSentReports && allSentReports.length > 0) {
			setReportHistoryView(allSentReports);
		}
	};

	const onSearchChange = (e) => {
		if (searchListInputRef.current) {
			if (searchListInputRef.current.value === '') {
				searchListInputRef.current.classList.remove('has-value');
			} else {
				searchListInputRef.current.classList.add('has-value');
			}
		}

		filterList();
	};

	const getCampaigns = async () => {
		let response = await GetActiveCampaigns();

		if (response && response.length > 0) {
			setActiveCampaigns(response);
		} else {
			setActiveCampaigns([]);
		}
	};

	const getReportHistory = async (fullReload = false) => {
		if (fullReload) {
			setIsLoading(true);
		}

		let response = await GetReportHistoryListWithSproc();

		if (response && response.length > 0) {
			let tempAllSentReports = response.filter((x) => x.type === 'Report' && x.status === 'Sent');

			setAllSentReports(tempAllSentReports);

			setReportHistoryView(tempAllSentReports);
		} else {
			setSentReportsView();
		}

		setIsLoading(false);
	};

	const setReportHistoryView = (reports) => {
		let tempSentReportsView = reports;

		if (searchListInputRef.current && searchListInputRef.current.value) {
			tempSentReportsView = reports.filter((x) =>
				x.campaignName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
			);
		}

		tempSentReportsView = tempSentReportsView.sort(function(a, b) {
			return new Date(b.dateSent) - new Date(a.dateSent);
		});

		setSentReportsView(tempSentReportsView);
	};

	const getReportTrackingLogs = async (id) => {
		//setIsGettingKeywordItems(true);

		const response = await GetReportHistoryTrackingLogs(id);

		if (response) {
			setTrackingDetailsData(response);
		}

		//setIsGettingKeywordItems(false);
	};

	const toggleTrackingDetailsDialog = (report) => {
		setShowTrackingDetailsDialog(!showTrackingDetailsDialog);

		if (!showTrackingDetailsDialog) {
			getReportTrackingLogs(report.id);
		} else {
			setTrackingDetailsData([]);
		}
	};

	return (
		<Fragment>
			<div className="container-fluid p-0">
				<div className="row">
					<div className="col-12">
						<PageHeadline
							headline="Reports"
							showSearchBox={true}
							buttonsHtml={
								<Fragment>
									{user && (
										<div className="d-flex justify-content-end">
											<div className="search-list-wrapper align-self-start">
												<input
													type="search"
													className="form-control"
													name="searchListTerm"
													onChange={onSearchChange}
													placeholder="Search List"
													ref={searchListInputRef}
													tabIndex="0"
												/>
												<button type="button" className="btn btn-link btn-search px-2">
													<i className="fa fa-search me-0" />
												</button>
											</div>
										</div>
									)}
								</Fragment>
							}
						/>
					</div>
				</div>

				<div className="main-content-container reports-list-container">
					<div className="main-content-inner-container">
						<select
							className="form-select m-3 campaign-selector"
							onChange={(e) => setSelectedCampaignId(e.target.value)}
							value={selectedCampaignId}
						>
							<option>- Select Campaign -</option>
							{activeCampaigns &&
								activeCampaigns.map((campaign, index) => (
									<option key={campaign.id} value={campaign.id}>
										{campaign.name}
									</option>
								))}
						</select>

						<table className="items-list reports-list table table-responsive">
							<thead>
								<tr>
									<th scope="col">Name</th>
									{(user.isAdmin || user.isSalesUser) && (
										<Fragment>
											<th className="count d-none d-sm-table-cell" scope="col">
												Opened
											</th>
											<th className="count d-none d-sm-table-cell" scope="col">
												Clicked
											</th>
										</Fragment>
									)}
									<th className="date text-start d-none d-sm-table-cell" scope="col">
										Next Report
									</th>
									<th className="date-time text-start d-none d-sm-table-cell" scope="col">
										Sent
									</th>
									<th scope="col" />
								</tr>
							</thead>
							{isLoading ? (
								<tbody>
									<tr>
										<td className="p-3" colSpan={11}>
											<Spinner />
										</td>
									</tr>
								</tbody>
							) : (
								<tbody>
									{sentReportsView && sentReportsView.length > 0 ? (
										sentReportsView.map((report, index) => (
											<tr key={index}>
												<td>{report.campaignName}</td>
												{(user.isAdmin || user.isSalesUser) && (
													<Fragment>
														<td className="count d-none d-sm-table-cell">
															{report.opens ? (
																<button
																	className="btn btn-link py-0 fw-bold text-decoration-underline"
																	title="View Details"
																	onClick={() => toggleTrackingDetailsDialog(report)}
																>
																	{report.opens}
																</button>
															) : (
																'0'
															)}
														</td>
														<td className="count d-none d-sm-table-cell">
															{report.clicks ? (
																<button
																	className="btn btn-link py-0 fw-bold text-decoration-underline"
																	title="View Details"
																	onClick={() => toggleTrackingDetailsDialog(report)}
																>
																	{report.clicks}
																</button>
															) : (
																'0'
															)}
														</td>
													</Fragment>
												)}
												<td className="date text-start d-none d-sm-table-cell">
													{report.campaignNextReportDate ? (
														<Moment format="MM/DD/YYYY">
															{report.campaignNextReportDate}
														</Moment>
													) : (
														''
													)}
												</td>
												<td className="date-time text-start d-none d-sm-table-cell">
													{report.dateSent ? (
														<Moment format="MM/DD/YYYY hh:mm A">{report.dateSent}</Moment>
													) : (
														''
													)}
												</td>
												<td className="task-action text-end">
													<a
														href={report.shareUrl}
														target="_blank"
														className="btn btn-link py-0"
														title="View Report"
													>
														<i className="fa-solid fa-magnifying-glass-chart" />
													</a>
													<a
														href={`/customer/report/${report.id}`}
														target="_blank"
														className="btn btn-link py-0"
														title="View Customer Report Info"
													>
														<i className="fa-solid fa-up-right-from-square" />
													</a>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td className="p-3" colSpan={5}>
												<i>No reports found</i>
											</td>
										</tr>
									)}
								</tbody>
							)}
							{!isLoading && (
								<TableFooter
									list={sentReportsView}
									colspan={6}
									showRefreshButton={true}
									onListRefresh={() => getReportHistory(true)}
								/>
							)}
						</table>
					</div>
				</div>

				{showTrackingDetailsDialog && (
					<ModalDialog position="right-sidebar" className="text-start">
						<ModalDialogContent align="start">
							<h4 className="mb-4">Report Tracking Details</h4>
							<form className="row">
								{trackingDetailsData && (
									<Fragment>
										<div className="col-5">
											<label className="form-label">
												<strong>Date</strong>
											</label>
										</div>
										<div className="col-4">
											<label className="form-label">
												<strong>IP Address</strong>
											</label>
										</div>
										<div className="col-3">
											<label className="form-label">
												<strong>Type</strong>
											</label>
										</div>

										{trackingDetailsData.map((trackingItem, index) => {
											let trackingItemDate = moment(trackingItem.date).format(
												'DD/MM/YYYY HH:mm:ss'
											);

											return (
												<Fragment key={index}>
													<div className="col-5">
														<label className="form-label">{trackingItemDate}</label>
													</div>
													<div className="col-4">
														<label className="form-label">{trackingItem.ipAddress}</label>
													</div>
													<div className="col-3">
														<label className="form-label">{trackingItem.type}</label>
													</div>
												</Fragment>
											);
										})}
									</Fragment>
								)}
							</form>
						</ModalDialogContent>
						<ModalDialogButtons>
							<div className="row w-100">
								<div className="text-start col-4">
									<button
										className="btn btn-outline-primary me-5"
										type="button"
										onClick={(e) => toggleTrackingDetailsDialog()}
									>
										Cancel
									</button>
								</div>
							</div>
						</ModalDialogButtons>
					</ModalDialog>
				)}
			</div>
		</Fragment>
	);
};

export default ReportsList;
