import './LeftNavigationHeader.css';
//import IconPhone from '../../../../img/phone_icon.png';
import { useStateValue } from '../../../StateProvider';
import Logo from '../../logo/Logo';

const LeftNavigationHeader = ({ collapsed }) => {
	const [ { user }, dispatch ] = useStateValue();

	return (
		<div className="app-left-nav-header">
			<Logo size={collapsed ? 'small' : ''} />
			{/* <div className="app-left-nav-header-text">Your Main Texting Number</div> */}
			<div className="app-left-nav-header-number">
				{/* <img src={IconPhone} /> */}
				{/* <span>{phoneNumber ? phoneNumber.friendlyName : ''}</span> */}
			</div>
		</div>
	);
};

export default LeftNavigationHeader;
