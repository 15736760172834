import axios from 'axios';
import { getToken } from './utils/StorageUtil';

let apiHost = 'https://gbpapi.gbpboost.com';
// let apiHost = 'https://dev-api.gbpboost.com';
const apiKey = 'AIzaSyBukTa2y70Uz3uRpXqhlmZdkBB3WAhxmvg';
const apiHeader = 'X-API-KEY';
const apiHeaderValue = 'IQO?EXZiro?-|m%X15;vx1Q>L}bxb,<?M7=(J56Ss[K7o+;^6a7}J!dW6i8LJk2';

if (window.location.origin) {
	if (window.location.origin.indexOf('localhost') > -1 || window.location.origin.indexOf('dev.') > -1) {
		// apiHost = 'https://localhost:7256';
		apiHost = 'https://dev-api.gbpboost.com';
	}
}

// setup axios to inject the authorization header automatically
// if token is present in store
axios.interceptors.request.use((config) => {
	let token = getToken();

	if (token && token.trim() !== '') {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});

export const GetSEOReportGridWithSproc = async (campaignId, reportId, targetId, compareTo) => {
	let start = performance.now();

	const response = await axios
		.get(
			apiHost +
				`/campaigns/reports/grid/${campaignId}/${reportId}/${targetId ? targetId : ''}${compareTo &&
				compareTo !== ''
					? '/' + compareTo
					: ''}`,
			null,
			{
				headers : {
					'Content-Type' : 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	let end = performance.now();
	console.log(
		'Function ' +
			(compareTo ? 'Compare GetSEOReportGridWithSproc' : 'GetSEOReportGridWithSproc') +
			' took: ' +
			(end - start) / 1000 +
			' seconds'
	);

	return response.data;
};

export const GetSEOReportRankWithSproc = async (campaignId, reportId, targetId, compareTo) => {
	let start = performance.now();

	const response = await axios
		.get(
			apiHost +
				`/campaigns/reports/rank/${campaignId}/${reportId}/${targetId ? targetId : ''}${compareTo &&
				compareTo !== ''
					? '/' + compareTo
					: ''}`,
			null,
			{
				headers : {
					'Content-Type' : 'application/json'
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	let end = performance.now();
	console.log(
		'Function ' +
			(compareTo ? 'Compare GetSEOReportRankWithSproc' : 'GetSEOReportRankWithSproc') +
			' took: ' +
			(end - start) / 1000 +
			' seconds'
	);

	return response.data;
};

export const GetSEOReportKeywords = async (campaignId, reportShortName) => {
	const response = await axios
		.get(apiHost + `/campaigns/reports/keywords/list/${campaignId}/${reportShortName}`, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetActiveCampaigns = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/list/active', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCanceledCampaigns = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/list/canceled', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetNewCampaignsForPortal = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/list/new/portal', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaign = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/details/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignReports = async (id) => {
	const response = await axios
		.get(apiHost + '/seo/reports/list/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignKeywords = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + id + '/keywords/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetReportHistoryListWithSproc = async () => {
	const response = await axios
		.get(apiHost + '/campaigns/reports/history/list/sproc', {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetReportHistoryDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/reports/history/' + id, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetReportHistoryTrackingLogs = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/reports/history/' + id + '/tracking-logs', {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignMessagesByType = async (id, type) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + id + '/messages/list/' + type, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignMessageDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/messages/details/' + id, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CreateCampaignMessage = async (campaignId, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + campaignId + '/messages/create', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteCampaign = async (id) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/delete', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CancelCampaign = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/' + id + '/cancel', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignFiles = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/' + id + '/files/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UploadCampaignFiles = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/files/upload', data, {
			headers : {
				// 'Content-Type' : 'application/json'
				mimeType : 'multipart/form-data'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const DeleteCampaignFiles = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/files/delete', JSON.stringify(data), {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const SubmitChangeRequest = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/change-request/submit', data, {
			headers : {
				// 'Content-Type' : 'application/json'
				mimeType : 'multipart/form-data'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const SubmitAskQuestionRequest = async (id, data) => {
	const response = await axios
		.post(apiHost + '/campaigns/' + id + '/ask-question/submit', data, {
			headers : {
				// 'Content-Type' : 'application/json'
				mimeType : 'multipart/form-data'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const AppLogin = async (data) => {
	const response = await axios
		.post(apiHost + '/accounts/login', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const SendForgotPasswordEmail = async (data) => {
	const response = await axios
		.post(apiHost + '/accounts/forgot-password', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const ResetUserPassword = async (data) => {
	const response = await axios
		.post(apiHost + '/accounts/reset-password', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetReportSettings = async () => {
	const response = await axios
		.get(apiHost + '/seo/reports/summary/settings/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetCampaignOrderDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/orders/' + id, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetRequirementDetails = async (id) => {
	const response = await axios
		.get(apiHost + '/campaigns/requirements/' + id, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetUserOffers = async () => {
	const response = await axios
		.get(apiHost + '/offers/', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetDefaultOffers = async () => {
	const response = await axios
		.get(apiHost + '/offers/default', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetOffer = async (id) => {
	const response = await axios
		.get(apiHost + '/offers/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const ProcessOrder = async (data) => {
	const response = await axios
		.post(apiHost + '/orders/process', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CreateNewRequirements = async (data) => {
	const response = await axios
		.post(apiHost + '/campaigns/requirements/create', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateRequirements = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/campaigns/requirements/' + id + '/update', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

//users
export const GetUser = async () => {
	const response = await axios
		.get(apiHost + '/accounts/details', {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const UpdateUser = async (id, data) => {
	const response = await axios
		.patch(apiHost + '/accounts/update/' + id, JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CancelSubscription = async (id, data) => {
	const response = await axios
		.post(apiHost + '/accounts/' + id + '/subscription/cancel', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

//Stripe methods
export const GetUserPayments = async (id) => {
	const response = await axios
		.get(apiHost + '/accounts/' + id + '/payments', {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};
