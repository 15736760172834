import { Link } from 'react-router-dom';
import './Logo.css';

const Logo = ({ size = '' }) => {
	return (
		<Link to="/">
			<div className={`app-logo ${size}`}>
				<img />
			</div>
		</Link>
	);
};

export default Logo;
