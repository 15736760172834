import { Fragment } from 'react';
import './OfferDetails.css';
import Logo from '../../../components/logo/Logo';
import { useParams } from 'react-router-dom';

const OfferDetailsSuccess = () => {
	const { id } = useParams();

	return (
		<Fragment>
			<div className="container-fluid offer-details-container pb-0">
				<div className="container">
					<div className="row">
						<div className="col-12 text-sm-start text-center">{<Logo size="medium" />}</div>
					</div>
				</div>
			</div>

			<div className="container-fluid offer-details-container">
				<div className="container text-center">
					<div className="offer-details-container-inner">
						<h3 className="offer-title">Order submitted successfuly!</h3>

						<div className="row mb-2 pt-3">
							<div className="col-12 p-4 pb-0">
								<a type="button" className="btn submit-button" href={`/orders/${id}/requirements`}>
									<span>Create Requirements</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default OfferDetailsSuccess;
