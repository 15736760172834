import ProfileIcon from '../account/profileIcon/ProfileIcon';
import './LeftNavigation.css';
import LeftNavigationHeader from './leftNavigationHeader/LeftNavigationHeader';
import LeftNavigationLinks from './leftNavigationLinks/LeftNavigationLinks';
import { useRef, useState } from 'react';
import { isMobile } from '../../utils/Common';
import { Fragment } from 'react';
import AskQuestionDialog from '../../screens/campaigns/askQuestionDialog/AskQuestionDialog';
import Logo from '../logo/Logo';

const LeftNavigation = () => {
	const [ collapsed, setCollapsed ] = useState(false);
	const leftNavHeaderRef = useRef();
	const collapseButtonIconRef = useRef();
	const navMenuOpenerMobileRef = useRef();
	const navMenuMobileRef = useRef();
	const [ showAskQuestionDialog, setShowAskQuestionDialog ] = useState(false);

	const toggleLeftNavBar = () => {
		leftNavHeaderRef.current.classList.toggle('collapsed');
		collapseButtonIconRef.current.classList.toggle('collapsed');
		if (collapseButtonIconRef.current.classList.contains('fa-angles-left')) {
			collapseButtonIconRef.current.classList.remove('fa-angles-left');
			collapseButtonIconRef.current.classList.add('fa-angles-right');
			setCollapsed(false);
		} else {
			collapseButtonIconRef.current.classList.remove('fa-angles-right');
			collapseButtonIconRef.current.classList.add('fa-angles-left');
			setCollapsed(true);
		}
	};

	const toggleLeftNavBarMobile = () => {
		navMenuMobileRef.current.classList.toggle('collapsed');
		// navMenuOpenerMobileRef.current.classList.toggle('collapsed');

		if (navMenuOpenerMobileRef.current.classList.contains('fa-times')) {
			navMenuOpenerMobileRef.current.classList.remove('fa-times');
			navMenuOpenerMobileRef.current.classList.add('fa-bars');
			setCollapsed(false);
		} else {
			navMenuOpenerMobileRef.current.classList.remove('fa-bars');
			navMenuOpenerMobileRef.current.classList.add('fa-times');
			setCollapsed(true);
		}
	};

	const handleNavLinkClickMobile = (type) => {
		if (isMobile()) {
			toggleLeftNavBarMobile();

			if (type === 'support') {
				toggleAskQuestionDialog();
			}
		}
	};

	const handleLinkClick = (type) => {
		if (type === 'support') {
			toggleAskQuestionDialog();
		}
	};

	const toggleAskQuestionDialog = async (e) => {
		setShowAskQuestionDialog(!showAskQuestionDialog);

		if (e) e.preventDefault();
	};

	return (
		<Fragment>
			{isMobile() ? (
				<Fragment>
					<div className="nav-mobile">
						<button onClick={toggleLeftNavBarMobile} className="nav-mobile-button">
							<i className="fas fa-bars" ref={navMenuOpenerMobileRef} />
						</button>
						<div className="flex-fill-remaining-space w-100 text-center me-4">
							<Logo size="small" />
						</div>
					</div>
					<div className="nav-menu-mobile-wrapper collapsed" ref={navMenuMobileRef}>
						<LeftNavigationLinks onLinkClick={handleNavLinkClickMobile} />
						<ProfileIcon onLinkClick={handleNavLinkClickMobile} />
					</div>
				</Fragment>
			) : (
				<div className="app-left-nav-container" ref={leftNavHeaderRef}>
					<LeftNavigationHeader collapsed={collapsed} />
					<LeftNavigationLinks onLinkClick={handleLinkClick} />
					<ProfileIcon />
				</div>
			)}

			{showAskQuestionDialog && (
				<AskQuestionDialog onSubmit={toggleAskQuestionDialog} onClose={toggleAskQuestionDialog} />
			)}
		</Fragment>
	);
};

export default LeftNavigation;
