import './CountDownTimer.css';
import ShowCounter from './ShowCounter';
import useCountDown from './useCountDown';

const CountDownTimer = ({ targetDate }) => {
	const [ days, hours, minutes, seconds ] = useCountDown(targetDate);

	if (days + hours + minutes + seconds <= 0) {
		return <ShowCounter days={0} hours={0} minutes={0} seconds={0} />;
	} else {
		return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />;
	}
};

export default CountDownTimer;
