import { useEffect, useState, Fragment } from 'react';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { CancelSubscription } from '../../../Services';
import { toast } from 'react-toastify';
import { useStateValue } from '../../../StateProvider';

const CancelSubscriptionDialog = ({ onSubmit, onClose }) => {
	const [ { user }, dispatch ] = useStateValue();
	const [ cancelSubscriptionConfirmValue, setCancelSubscriptionConfirmValue ] = useState();
	const [ cancelSubscriptionRequestSent, setCancelSubscriptionRequestSent ] = useState(false);
	const [ cancelReason, setCancelReason ] = useState();
	const [ isSubmitting, setIsSubmitting ] = useState(false);

	const handleCancelSubscription = async (e) => {
		setIsSubmitting(true);

		if (!cancelReason || cancelReason.trim() === '') {
			toast.error('Enter reason for cancellation to continue');
			setIsSubmitting(false);
			return;
		}

		if (!cancelSubscriptionConfirmValue || cancelSubscriptionConfirmValue !== 'CANCEL') {
			toast.error('Enter word CANCEL in capital letters to continue');
			setIsSubmitting(false);
			return;
		}

		setTimeout(async function() {
			let data = { CancelReason: cancelReason };
			let response = await CancelSubscription(user.id, data);

			if (response.success) {
				setIsSubmitting(false);
				setCancelSubscriptionRequestSent(true);

				if (onSubmit) {
					onSubmit();
				}
			} else {
				setIsSubmitting(false);
				toast.error('Request failed to send. Please try again');
			}
		}, 1000);

		if (e) e.preventDefault();
	};

	const handleCloseDialog = () => {
		setCancelSubscriptionConfirmValue();
		setCancelSubscriptionRequestSent(false);

		if (onClose) {
			onClose();
		}
	};

	return (
		<ModalDialog className="text-start" position="right-sidebar">
			<ModalDialogContent align="start">
				<h4 className="mb-4">
					{cancelSubscriptionRequestSent ? (
						<span>
							<i class="fa-solid fa-circle-check text-success me-3" />Success!
						</span>
					) : (
						<span>Cancel Subscription Request</span>
					)}
				</h4>

				{cancelSubscriptionRequestSent ? (
					<Fragment>
						<p>Cancel subscription request successfully sent.</p>
						<p className="mb-0">Our support team will let you know when this process is completed.</p>
					</Fragment>
				) : (
					<Fragment>
						<div className="row mb-4">
							<label htmlFor="name" className="form-label col-12">
								Reason for cancellation:
							</label>
							<div className="col-12">
								<textarea
									className="form-control"
									name="name"
									value={cancelReason || ''}
									onChange={(e) => setCancelReason(e.target.value)}
									rows={5}
									required
								/>
							</div>
						</div>
						<p className="mb-1">
							Enter word CANCEL in capital letters in order to send cancel subscription request.
						</p>
						<div>
							<input
								type="text"
								className="form-control w-100"
								value={cancelSubscriptionConfirmValue}
								onChange={(e) => setCancelSubscriptionConfirmValue(e.target.value)}
							/>
						</div>
					</Fragment>
				)}
			</ModalDialogContent>
			<ModalDialogButtons>
				<div className="row">
					<div className="text-start col-12">
						{!cancelSubscriptionRequestSent && (
							<button className="btn btn-primary" onClick={handleCancelSubscription}>
								{isSubmitting ? (
									<Fragment>
										<span className="spinner-border m-0 me-2" />
										<span>Confirming...</span>
									</Fragment>
								) : (
									<span>Confirm</span>
								)}
							</button>
						)}
						<button className="btn btn-outline-primary ms-2" onClick={(e) => handleCloseDialog()}>
							{cancelSubscriptionRequestSent ? 'Close' : 'Cancel'}
						</button>
					</div>
				</div>
			</ModalDialogButtons>
		</ModalDialog>
	);
};

export default CancelSubscriptionDialog;
