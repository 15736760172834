import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import './PaymentsList.css';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import Moment from 'react-moment';
import TableFooter from '../../../components/table/footer/TableFooter';
import { useStateValue } from '../../../StateProvider';
import { GetUserPayments } from '../../../Services';

const PaymentsList = () => {
	const [ { user }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ payments, setPayments ] = useState([]);

	useEffect(() => {
		document.title = 'Payments History';

		getPayments(true);
	}, []);

	const getPayments = async () => {
		setIsLoading(true);

		let response = await GetUserPayments(user.id);

		if (response && response.length > 0) {
			let tempActivePayments = response;

			//tempActivePayments = tempActivePayments.sort((a, b) => a.name.localeCompare(b.name));
			tempActivePayments = tempActivePayments.sort(function(a, b) {
				return new Date(b.created) - new Date(a.created);
			});

			setPayments(tempActivePayments);
		}

		setIsLoading(false);
	};

	return (
		<Fragment>
			<div className="row">
				<div className="col-12">
					<PageHeadline headline="Payments History" showSearchBox={false} />
				</div>
			</div>

			<Fragment>
				<div className="main-content-container payments-list-container">
					<div className="main-content-inner-container">
						<table className="items-list payments-list table table-responsive">
							<thead>
								<tr>
									<th className="date" scope="col">
										Created
									</th>
									<th className="payment-campaign-name text-truncate" scope="col">
										Campaign
									</th>
									<th className="d-none d-sm-table-cell" scope="col">
										Description
									</th>
									<th className="status d-none d-sm-table-cell text-center" scope="col">
										Status
									</th>
									<th className="count text-center" scope="col">
										Amount
									</th>
								</tr>
							</thead>
							{isLoading ? (
								<tbody>
									<tr>
										<td className="p-3" colSpan={5}>
											<Spinner />
										</td>
									</tr>
								</tbody>
							) : (
								<tbody>
									{payments && payments.length > 0 ? (
										payments.map((payment, index) => {
											return (
												<Fragment key={index}>
													<tr>
														<td className="date">
															{payment.created ? (
																<Moment format="MM/DD/YYYY">{payment.created}</Moment>
															) : (
																''
															)}
														</td>
														<td
															className="payment-campaign-name text-truncate"
															title={payment.campaignName}
														>
															{payment.campaignName}
														</td>
														<td className="d-none d-sm-table-cell">
															{payment.description}
														</td>
														<td className="status d-none d-sm-table-cell text-center">
															{payment.status}
														</td>
														<td className="count text-center">${payment.amount}</td>
													</tr>
												</Fragment>
											);
										})
									) : (
										<tr>
											<td className="p-3" colSpan={5}>
												<i>No payments</i>
											</td>
										</tr>
									)}
								</tbody>
							)}

							{!isLoading && (
								<TableFooter
									list={payments}
									colspan={5}
									showRefreshButton={true}
									onListRefresh={() => getPayments(true)}
								/>
							)}
						</table>
					</div>
				</div>
			</Fragment>
		</Fragment>
	);
};

export default PaymentsList;
