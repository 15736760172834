import './Account.css';
import { useStateValue } from './../../StateProvider';
import { Fragment, useState, useEffect } from 'react';
import PageHeadline from '../pageHeadline/PageHeadline';
import { GetUser, UpdateUser } from '../../Services';
import { toast } from 'react-toastify';
import Spinner from '../spinner/Spinner';
import { actionTypes } from '../../reducer';
import CancelSubscriptionDialog from './cancelSubscriptionDialog/CancelSubscriptionDialog';

function Account() {
	const [ { user }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ userFormData, setUserFormData ] = useState({});
	const [ showCancelSubscriptionDialog, setShowCancelSubscriptionDialog ] = useState(false);

	useEffect(() => {
		if (user) {
			getUserDetails();
		}
	}, []);

	const getUserDetails = async () => {
		setIsLoading(true);

		let response = await GetUser();

		if (response) {
			setUserFormData(response);
		}

		setIsLoading(false);
	};

	const handleChange = async (e) => {
		setUserFormData({
			...userFormData,
			[e.target.name]: e.target.value
		});
	};

	const handleSaveChanges = async (e) => {
		e.preventDefault();

		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		let tempFormData = {
			...userFormData,
			phone : userFormData.phoneNumber
		};

		let response = await UpdateUser(user.id, tempFormData);

		if (response.success) {
			toast.success('Settings saved.');

			dispatch({
				type : actionTypes.SET_USER,
				user : response.data
			});
		}
	};

	const toggleCancelSubscriptionDialog = async (e) => {
		setShowCancelSubscriptionDialog(!showCancelSubscriptionDialog);

		if (e) e.preventDefault();
	};

	return (
		<div className="app">
			{user && (
				<div className="container-fluid p-0">
					<div className="row">
						<div className="col-12">
							<PageHeadline headline="Account Details" showSearchBox={false} />
						</div>
					</div>

					<div className="main-content-container account-details-container">
						<div className="main-content-inner-container">
							{isLoading ? (
								<Spinner />
							) : (
								<form className="row account-details-form text-start p-3 p-xl-4">
									<label htmlFor="firstName" className="form-label col-12">
										First Name:
									</label>
									<div className="col-12 mb-3">
										<input
											type="text"
											className="form-control"
											name="firstName"
											value={userFormData.firstName || ''}
											onChange={handleChange}
											required
										/>
									</div>
									<label htmlFor="lastName" className="form-label col-12">
										Last Name:
									</label>
									<div className="col-12 mb-3">
										<input
											type="text"
											className="form-control"
											name="lastName"
											value={userFormData.lastName || ''}
											onChange={handleChange}
											required
										/>
									</div>
									{/* <label htmlFor="email" className="form-label col-12">
											Email:
										</label>
										<div className="col-12 mb-3">
											<input
												type="email"
												className="form-control"
												name="email"
												value={userFormData.email || ''}
												onChange={handleChange}
												required
											/>
										</div>
									 */}
									<label htmlFor="phoneNumber" className="form-label col-12">
										Phone:
									</label>
									<div className="col-12 mb-3">
										<input
											type="phoneNumber"
											className="form-control"
											name="phoneNumber"
											value={userFormData.phoneNumber || ''}
											onChange={handleChange}
											required
										/>
									</div>
									<div className="col-6">
										<button type="button" className="btn btn-primary" onClick={handleSaveChanges}>
											Save Changes
										</button>
									</div>
									{/* <div className="col-6 ps-0 d-flex justify-content-end">
										<button
											type="button"
											className="btn btn-danger"
											onClick={(e) => toggleCancelSubscriptionDialog(e)}
										>
											Cancel Subscription
										</button>
									</div> */}
								</form>
							)}
						</div>
					</div>

					{showCancelSubscriptionDialog && (
						<CancelSubscriptionDialog
							onSubmit={toggleCancelSubscriptionDialog}
							onClose={toggleCancelSubscriptionDialog}
						/>
					)}
				</div>
			)}
		</div>
	);
}

export default Account;
