import { Fragment } from 'react';
import './CreateRequirements.css';
import Logo from '../../../components/logo/Logo';

const CreateRequirementsSuccess = () => {
	return (
		<Fragment>
			<div className="container-fluid requirements-details-container pb-0">
				<div className="container">
					<div className="row">
						<div className="col-12 text-sm-start text-center">{<Logo size="medium" />}</div>
					</div>
				</div>
			</div>

			<div className="container-fluid requirements-details-container">
				<div className="container text-center">
					<div className="requirements-details-container-inner">
						<h3 className="requirements-title">Requirements submitted successfuly!</h3>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default CreateRequirementsSuccess;
