import { useState, Fragment } from 'react';
import './ForgotPassword.css';
import { SendForgotPasswordEmail } from '../../Services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Logo from '../logo/Logo';

const ForgotPassword = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ email, setEmail ] = useState('');
	const [ isEmailTriggered, setIsEmailTriggered ] = useState(false);
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (email === '') {
			toast.error('Valid email address is required');
			return;
		}

		setIsLoading(true);

		let data = {
			email : email
		};

		const response = await SendForgotPasswordEmail(data);

		if (response) {
			setIsEmailTriggered(true);
		}

		setIsLoading(false);
	};

	return (
		<div className="container-fluid">
			<div className="container reset-password-container">
				<div className="reset-password-wrapper">
					<div className="text-center mb-4">
						<Logo />
					</div>

					<h4 className="text-center mb-4">Forgot Your Password</h4>
					{!isEmailTriggered ? (
						<Fragment>
							<p>
								Enter your login email address to reset your password. An email will be sent to your
								inbox with instructions on resetting the account password.
							</p>
							<form onSubmit={handleSubmit}>
								<div className="row mb-4">
									<div className="col-12">
										<label htmlFor="email" className="form-label">
											Email:
										</label>
										<input
											type="email"
											className="form-control"
											id="email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											disabled={isLoading}
										/>
									</div>
								</div>
								<div className="row pt-2">
									<div className="col-12">
										<button className="btn btn-primary w-100" type="submit">
											{isLoading ? (
												<Fragment>
													<span className="spinner-border m-0 me-2" />
													{/* <span>Logging in...</span> */}
												</Fragment>
											) : (
												<span>Continue</span>
											)}
										</button>
									</div>
								</div>
							</form>
						</Fragment>
					) : (
						<Fragment>
							<div className="alert alert-secondary m-0">
								If an account is found under the email address you entered an email will be sent to your
								inbox with instructions on resetting the account password.
							</div>
						</Fragment>
					)}

					<div className="row">
						<div className="col-12">
							<button
								type="button"
								className="btn btn-link btn-block btn-forgot-password w-100 mt-3 p-0"
								onClick={() => navigate(-1)}
							>
								<i className="fa-solid fa-chevron-left" />
								<span>Go Back</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
