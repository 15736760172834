import { useEffect, useState, Fragment } from 'react';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { CancelCampaign } from '../../../Services';
import { toast } from 'react-toastify';

const CancelCampaignConfirmDialog = ({ campaignId, cancelReason, onSubmit, onClose }) => {
	const [ cancelSubscriptionConfirmValue, setCancelSubscriptionConfirmValue ] = useState('');
	const [ isSubmitting, setIsSubmitting ] = useState(false);

	const handleCancelCampaign = async (e) => {
		setIsSubmitting(true);

		if (!cancelSubscriptionConfirmValue || cancelSubscriptionConfirmValue !== 'CANCEL') {
			toast.error('Enter word CANCEL in capital letters to continue');
			setIsSubmitting(false);
			return;
		}

		setTimeout(async function() {
			let data = {
				campaignId   : campaignId,
				cancelReason : cancelReason
			};

			const response = await CancelCampaign(campaignId, data);

			if (response.success) {
				setIsSubmitting(false);

				if (onSubmit) {
					onSubmit();
				}
			} else {
				setIsSubmitting(false);
				toast.error('Request failed. Please try again');
			}
		}, 1000);

		if (e) e.preventDefault();
	};

	const handleCloseDialog = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<ModalDialog className="text-start">
			<ModalDialogContent align="start">
				<h4 className="mb-4">
					<span>Confirm Campaign Cancellation</span>
				</h4>

				<div className="row mb-4">
					<div className="col-12">
						<p className="mb-1">
							Please enter the word CANCEL in all caps and hit submit to process cancellation.
						</p>
						<div>
							<input
								type="text"
								className="form-control w-100"
								value={cancelSubscriptionConfirmValue}
								onChange={(e) => setCancelSubscriptionConfirmValue(e.target.value)}
							/>
						</div>
					</div>
				</div>
			</ModalDialogContent>
			<ModalDialogButtons>
				<div className="row">
					<div className="text-start col-12">
						<button className="btn btn-primary" onClick={handleCancelCampaign}>
							{isSubmitting ? (
								<Fragment>
									<span className="spinner-border m-0 me-2" />
									<span>Submitting...</span>
								</Fragment>
							) : (
								<span>Submit</span>
							)}
						</button>
						<button className="btn btn-outline-primary ms-2" onClick={(e) => handleCloseDialog()}>
							<span>Cancel</span>
						</button>
					</div>
				</div>
			</ModalDialogButtons>
		</ModalDialog>
	);
};

export default CancelCampaignConfirmDialog;
