import './MainAppContentArea.css';

const MainAppContentArea = ({ children }) => {
	return (
		<div className="app-content-container">
			<div className="container app-content-inner-container">{children}</div>
		</div>
	);
};

export default MainAppContentArea;
